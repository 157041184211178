import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Content from 'components/category/content'
import Videos from 'components/category/videos'
import Layout from 'components/layout'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Button from 'components/button/button'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const items = [
  {
    link: '/agriculture/planting/air-drills/',
    imageId: 'airSeedingImage',
    name: 'Air Drills',
    alt: 'John Deere Air Drills',
  },
  {
    link: '/agriculture/planting/box-drills/',
    imageId: 'boxDrillsImage',
    name: 'Box Drills',
    alt: 'John Deere Box Drills',
  },
  {
    link: '/agriculture/planting/drawn-planters/',
    imageId: 'drawnPlantersImage',
    name: 'Drawn Planters',
    alt: 'John Deere Drawn Planters',
  },
  {
    link: '/agriculture/planting/db-planters/',
    imageId: 'dbPlantersImage',
    name: 'DB Planters',
    alt: 'John Deere DB Planters',
  },
]

const PlantingPage = ({ data }) => {
  const { allHutsonPromotion, allDeereOffer, heroImage, planterPUKImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  if (allDeereOffer) {
    allDeereOffer.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Planting Equipment | Hutson Inc</title>
        <meta
          name='description'
          content='Maximize your yield with a new John Deere planter from Hutson Inc. Check out our full line of drawn planters, air seeders, box drills, and DB planters and see what fits your needs.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Agriculture Equipment',
                'item': 'https://www.hutsoninc.com/agriculture/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Planting',
                'item': 'https://www.hutsoninc.com/agriculture/planting/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Planting Equipment' />

      <Content>
        <Floater items={gridItems} />

        <Promos data={promos} type='Planting Equipment' />

        <Videos
          type='Planting Equipment'
          id='0jriw8mFQCU'
          list='PLUz5fj7f_mw-vlgT0QzSkk7jLvSHK_yQD'
        />
      </Content>
      <FlexGrid>
        <div>
          <GatsbyImage
            image={getImage(planterPUKImage)}
            objectFit='cover'
            objectPosition='50% 50%'
            style={{ height: '100%', width: '100%' }}
            alt=''
          />
        </div>
        <FlexGridTextColumn>
          <H3 as='h2'>Planter Precision Upgrades</H3>
          <P>
            Get more out of your current equipment with a Precision Upgrade Kit. Enhance planting
            accuracy, improve seed placement and emergence, and boost crop yields.
          </P>
          <div>
            <Button
              as={ButtonOutboundLink}
              color='green'
              ghost
              href='https://www.deere.com/en/technology-products/precision-ag-technology/precision-upgrades/planter-upgrades/'
            >
              Learn more at Deere.com
            </Button>
            <SecondaryOutboundLink href='https://www.deere.com/en/technology-products/precision-ag-technology/precision-upgrades/planter-upgrades/planter-upgrades-form/'>
              Find available upgrades for your planter
            </SecondaryOutboundLink>
          </div>
        </FlexGridTextColumn>
      </FlexGrid>
    </Layout>
  )
}

const FlexGrid = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;

  @media (min-width: 900px) {
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};

    > * {
      flex-grow: 0;
      flex-shrink: 0;
      width: 50%;
    }
  }
`

const FlexGridTextColumn = styled.div`
  background-color: ${props => props.theme.color.n20};
  padding: 36px 24px;

  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 64px;
  }
`

const ButtonOutboundLink = styled(OutboundLink)`
  display: inline-block;
  margin: 8px 0;
  text-decoration: none;
`

const SecondaryOutboundLink = styled(OutboundLink)`
  color: ${props => props.theme.color.g400};
  display: block;
  margin: 16px 0 0;
  text-decoration: underline;

  :hover,
  :focus,
  :active {
    text-decoration: none;
  }
`

export const pageQuery = graphql`
  query plantingSubcategoryQuery($subcategory: String = "planting") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $subcategory }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    allDeereOffer(filter: { subcategoryList: { in: [$subcategory] } }, limit: 3) {
      nodes {
        ...DeerePromo
      }
    }
    heroImage: file(relativePath: { eq: "headers/planting-subcategory-header.jpg" }) {
      ...FullWidthImage
    }
    airSeedingImage: file(relativePath: { eq: "agriculture/air-seeding.jpg" }) {
      ...FloatingGridImage
    }
    boxDrillsImage: file(relativePath: { eq: "agriculture/box-drills.jpg" }) {
      ...FloatingGridImage
    }
    drawnPlantersImage: file(relativePath: { eq: "agriculture/drawn-planters.jpg" }) {
      ...FloatingGridImage
    }
    dbPlantersImage: file(relativePath: { eq: "agriculture/db-planters.jpg" }) {
      ...FloatingGridImage
    }
    planterPUKImage: file(
      relativePath: { eq: "precision-upgrades/planter-precision-upgrade-kit.jpg" }
    ) {
      ...SharpImage900
    }
  }
`

export default PlantingPage
